const GetPlacesUrl = (siteID) => {
  switch (siteID) {
    case 'MLB':
      return 'https://vendedores.mercadolivre.com.br/nota/como-enviar-suas-vendas-nas-agencias-mercado-livre';
    case 'MLA':
      return 'https://vendedores.mercadolibre.com.ar/nota/como-enviar-tus-ventas-usando-los-puntos-de-despacho';
    case 'MLC':
      return 'https://vendedores.mercadolibre.cl/nota/como-enviar-tus-ventas-usando-los-centros-de-envio';
    case 'MCO':
      return 'https://vendedores.mercadolibre.com.co/nota/como-enviar-tus-ventas-usando-las-agencias-de-mercado-libre';
    case 'MLM':
      return 'https://vendedores.mercadolibre.com.mx/nota/como-enviar-tus-ventas-usando-las-agencias-de-mercado-libre';
    case 'MLU':
      return 'https://vendedores.mercadolibre.com.uy/nota/como-enviar-tus-ventas-usando-las-agencias-de-mercado-libre';
    default:
      return 'https://vendedores.mercadolibre.com/';
  }
};

export { GetPlacesUrl };
