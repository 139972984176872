import React from 'react';

import DispatchSteps from '@components/envios/dispatch-steps';
import MEBenefits from '@components/envios/me-benefits';
import FSBenefits from '@components/envios/fs-benefits';
import { GetPlacesUrl } from '../../../common/constants';

const fsBenefitList = [
  {
    title: 'Cubrimos hasta 50% del costo de tus envíos gratis',
    text: (
      <>
        En Mercado Libre, las ventas desde $ 30.000 tienen envíos gratis y te
        pagamos hasta el 50% del costo de esos envíos.
        <div className="separator" />
        <a
          href="https://www.mercadolibre.com.ar/ayuda/costos-envios-gratis_3482"
          target="_blank"
          rel="noopener noreferrer"
        >
          Revisar costos y descuentos
        </a>
      </>
    ),
    image: 'accesible',
  },
  {
    title: 'Mejorá la exposición de tus publicaciones',
    text: 'Tus productos con envíos gratis tendrán un filtro exclusivo y podrás ganar posiciones en los listados.',
    image: 'outstanding',
  },
];

const dispatchStepList = [
  {
    icon: 'printer',
    subTitle: 'Cada venta genera una etiqueta de envío.',
  },
  {
    icon: 'box',
    subTitle: 'Solo tenés que preparar tu producto.',
  },
  {
    icon: 'place',
    subTitle: (
      <>
        Y llevarlo a un{' '}
        <a
          href={GetPlacesUrl("MLA")}
          target="_blank"
          rel="noopener noreferrer"
        >
          punto de despacho
        </a>
        .
      </>
    ),
  },
];

const meBenefitList = [
  {
    image: 'payment',
    title: 'Cobrás más rápido',
    text: 'Liberaremos el dinero entre 8 y 14 días después de que llegue el producto.',
  },
  {
    image: 'secure',
    title: 'Contás con nuestro respaldo',
    text: 'Todos tus paquetes están asegurados. Si hay algún imprevisto, te cubrimos.',
  },
  {
    image: 'tracking',
    title: 'Los envíos tienen seguimiento',
    text: 'Vos y las personas que te compren pueden seguir el envío todo el tiempo.',
  },
  {
    image: 'coordination',
    title: 'No tenés que coordinar cada envío',
    text: 'Quien te compra elige el envío al momento de comprar y lo paga por Mercado Pago.',
  },
  {
    image: 'cart',
    title: 'Vendé más con carrito',
    text: 'Quienes te compren podrán sumar varios de tus productos al carrito y ahorrar en los costos de envío.',
  },
  {
    image: 'reputation',
    title: 'Mejorás tu reputación',
    text: 'Si despachás rápido, tu reputación mejora y podrás obtener más descuentos en los costos de envíos gratis.',
  },
];

const Benefits = () => (
  <div className="benefits">
    <div className="push-benefits">
      <FSBenefits benefitList={fsBenefitList} />
    </div>

    <DispatchSteps title="Simplificá tus tareas" steps={dispatchStepList} />

    <MEBenefits title="Y obtené más beneficios" benefitList={meBenefitList} />
  </div>
);

export default Benefits;
